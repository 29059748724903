// extracted by mini-css-extract-plugin
export var books_container = "books-module--books_container--15mvq";
export var books_container__title_area = "books-module--books_container__title_area--IA4hP";
export var books_container__head = "books-module--books_container__head--3OzvZ";
export var books_container__book_title = "books-module--books_container__book_title--2D04W";
export var books_container__info = "books-module--books_container__info--1CW-M";
export var books_container__summary = "books-module--books_container__summary--2pNQ5";
export var book_info = "books-module--book_info--3TRj4";
export var book_info__fig = "books-module--book_info__fig--TK8_O";
export var book_info__list = "books-module--book_info__list--YT-cn";
export var book_info__title = "books-module--book_info__title--3ajNJ";
export var book_info__text = "books-module--book_info__text--1u2-2";
export var book_info__button = "books-module--book_info__button--r3J7l";