import React, { useContext } from 'react';
import { PageProps, graphql } from 'gatsby';
import BooksDetail from '@/components/Books/BooksDetail';
import { Context } from '@/components/Layout';

const BooksPage: React.FC<PageProps<GatsbyTypes.BooksPageQuery>> = ({
  data,
}) => {
  const loading = useContext(Context);
  return !loading && <BooksDetail data={data} />;
};

export default BooksPage;

export const query = graphql`
  query BooksPage($id: String!) {
    microcmsBooks(id: { eq: $id }) {
      id
      booksId
      title
      content
      authors {
        employee
        name
      }
      image {
        url
        height
        width
      }
      tag {
        tag
        id
      }
      store {
        seven
        amazon
        rakuten
      }
      publisher
      issuedDate(formatString: "YYYY年MM月")
      nominalCost
    }
    microcmsEnv {
      commonThumbnail {
        url
      }
    }
  }
`;
