// ■コンポーネント説明
// 書籍下書き表示用コンポーネント

import React from 'react';
import SEO from '@/components/seo';
import CDNReplace from '@/components/CDNReplace';
import { Link } from 'gatsby';
import ContactBox from '@/components/Contact/ContactBox';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import UILabel from '@/components/UI/UILabel';

import * as styles from '@/styles/page/books.module.scss';

export interface BooksDetailProps {
  data: GatsbyTypes.BooksPageQuery;
}

const BooksDetail: React.FC<BooksDetailProps> = (props) => {
  const { data } = props;

  return (
    <>
      <SEO title="書籍紹介" />
      <div className="page-wrap">
        <div className={styles.books_container}>
          <div className={styles.books_container__title_area}>
            <div className={styles.books_container__head}>
              {data.microcmsBooks.tag.map(({ tag, id }) => (
                <UILabel key={id} text={tag} />
              ))}
            </div>
            <h1 className={styles.books_container__book_title}>
              {data.microcmsBooks.title}
            </h1>
          </div>
          <div className={styles.books_container__info}>
            <aside className={styles.book_info}>
              <figure className={styles.book_info__fig}>
                <img
                  loading="lazy"
                  src={`${CDNReplace(
                    data.microcmsBooks.image.url,
                  )}?w=200&auto=format&q=75`}
                  alt={data.microcmsBooks.title}
                />
              </figure>
              <dl className={styles.book_info__list}>
                <dt className={styles.book_info__title}>著者</dt>
                <dd className={styles.book_info__text}>
                  {data.microcmsBooks.authors.map(
                    ({ name, employee }, index) => (
                      <span key={name} className={employee ? `employee` : ``}>
                        {name}
                        {data.microcmsBooks.authors.length - 1 === index
                          ? ``
                          : `,`}
                      </span>
                    ),
                  )}
                </dd>
                <dt className={styles.book_info__title}>発行</dt>
                <dd className={styles.book_info__text}>
                  {data.microcmsBooks.publisher}
                </dd>
                <dt className={styles.book_info__title}>発行日</dt>
                <dd className={styles.book_info__text}>
                  {data.microcmsBooks.issuedDate}
                </dd>
                <dt className={styles.book_info__title}>定価</dt>
                <dd className={styles.book_info__text}>
                  {data.microcmsBooks.nominalCost}
                </dd>
              </dl>
              {data.microcmsBooks.store && (
                <>
                  {data.microcmsBooks.store.amazon && (
                    <div className={styles.book_info__button}>
                      <Link
                        to={data.microcmsBooks.store.amazon}
                        target="_blank"
                        className="button-03"
                        rel="noreferrer"
                      >
                        Amazonで購入
                      </Link>
                    </div>
                  )}
                  {data.microcmsBooks.store.rakuten && (
                    <div className={styles.book_info__button}>
                      <Link
                        to={data.microcmsBooks.store.rakuten}
                        target="_blank"
                        className="button-03"
                        rel="noreferrer"
                      >
                        楽天で購入
                      </Link>
                    </div>
                  )}
                  {data.microcmsBooks.store.seven && (
                    <div className={styles.book_info__button}>
                      <Link
                        to={data.microcmsBooks.store.seven}
                        target="_blank"
                        className="button-03"
                        rel="noreferrer"
                      >
                        セブンネットショッピングで購入
                      </Link>
                    </div>
                  )}
                </>
              )}
            </aside>
          </div>
          <div className={`${styles.books_container__summary} section editor`}>
            <section
              className="section-inner"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `${data.microcmsBooks.content}`,
              }}
            />
          </div>
        </div>
      </div>
      <ContactBox />
      <UIBreadcrumb
        UpperItem={[
          [`Works`, `/works`],
          [`Books`, `/books`],
        ]}
        CurrentItem={data.microcmsBooks.title}
      />
    </>
  );
};

export default BooksDetail;
